<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import { FieldTypeEnum } from "@/core/enums/field-types";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    FormModal,
    MasterDetail
  },
  props: {
    editable: Boolean,
    endDate: String,
    lifeCycle: {
      type: Number,
      default: 0
    },
    startDate: String,
  },
  data() {
    return {
      taxModal: {
        data: {},
        opened: false
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      if (!this.editable) {
        return [];
      }

      return [
        {
          text: "Alterar taxa de juros",
          icon: "mdi-percent-box-outline",
          action: async () => {
            const taxa_juros_anual = await this.doGetTax();
            this.taxModal.data = { taxa_juros_anual };
            this.taxModal.opened = true;
          }
        }
      ];
    },
    businessPlanId() {
      return this.$route.params.id;
    },
    cols() {
      const months = this.competencias.map(key => ({
        key,
        name: moment(key, "YYYY-MM").format("MM/YYYY"),
        nowrap: true,
        sortable: false,
        type: this.$fieldTypes.TEXT
      }));

      return [
        {
          key: "rubrica",
          name: "",
          nowrap: true,
          sortable: false,
          type: this.$fieldTypes.TEXT
        },
        ...months
      ];
    },
    competencias() {
      const projectStart = this.startDate ? moment(this.startDate, 'YYYY-MM') : moment();
      const projectEnd = this.endDate ? moment(this.endDate, 'YYYY-MM') : moment();
      const amortizationEnd = projectEnd.add(this.lifeCycle, 'years');
      const size = amortizationEnd.diff(projectStart, 'months') + 1;
      return Array.from({ length: size }, (_, index) => {
        return moment(projectStart).add(index, 'months').format('YYYY-MM');
      });
    },
    // TODO terminar integração
    customResource() {
      // const mock = [
      //   ["Custos", ["Software", "Cloud", "COGs", "People"]],
      //   ["Retorno", ["Aumento de receita", "Redução de custo"]],
      //   ["Fluxo líquido"],
      //   ["FCL descontado"],
      //   ["FCL descontado Acum."]
      // ];
      // const self = this;
      const resource = this.apiResource(`v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/vpl/rubrica`);

      return {
        async get() {
          // const formatValues = target => {
          //   return Object.entries(target).reduce((ref, [key, value]) => {
          //     if (moment(key, "YYYY-MM").isValid()) {
          //       ref[key] = value ? toCurrency(value) : " -- ";
          //     }

          //     return ref;
          //   }, target);
          // };

          const response = await resource.get();
          return response;

          // return data.map(row => {
          //   if (Array.isArray(row)) {
          //     let [mainRow, subRows] = row;
          //     mainRow = formatValues(mainRow);
          //     subRows = subRows.map(sub => formatValues(sub));
          //     return [mainRow, subRows];
          //   }

          //   return formatValues(row);
          // });
        }
      };
    },
    fields() {
      return [
        {
          key: "taxa_juros_anual",
          name: "Taxa de juros",
          type: FieldTypeEnum.NUMBER,
          rules: [{ rule: "required" }]
        }
      ];
    },
    taxResource() {
      return this.apiResource(
        `v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/vpl/parametrizacao`
      );
    }
  },
  methods: {
    async doGetTax() {
      const response = await this.taxResource.get();
      const value = response.taxa_juros_anual || '';
      return value;
    },
    async doSaveTax(data, close) {
      try {
        const { taxa_juros_anual } = data;
        await this.taxResource.save({
          taxa_juros_anual: taxa_juros_anual
        });

        if (close) {
          close();
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    }
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :actionBarButtons="actionBarButtons"
    :canDelete="false"
    :canEdit="false"
    class="vpl-table"
    :cols="cols"
    :customResource="customResource"
    disableContextMenu
    disablePagination
    expandSubItem
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :hasNewButton="false"
    height="auto"
    resourceId="rubrica"
  >
    <FormModal
      :cols="fields"
      :opened.sync="taxModal.opened"
      title="Alterar taxa de juros"
      :value.sync="taxModal.data"
      @save="doSaveTax"
    />
  </MasterDetail>
</template>

<style lang="scss" scoped>
.vpl-table {
  height: 100%;

  &::v-deep .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text {
      flex-shrink: 0;
    }

    .v-data-table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .v-data-table__wrapper {
      flex-grow: 1;
      overflow: auto;
    }

    .v-data-footer {
      flex-shrink: 0;
    }
  }
}
</style>
