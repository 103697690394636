<template>
  <v-row class="pr-4">
    <Field
      v-for="(field, index) of fields.businessPlan"
      class="pl-4"
      :colSize="field.colSize"
      :hideInform="field.hideInForm"
      :key="`business-plan-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h3>Informações da iniciativa</h3>
    </v-col>
    <Field
      v-for="(field, index) of fields.iniciativa"
      class="pl-4"
      :colSize="field.colSize"
      :key="`iniciativa-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h3>IAS38 - Framework</h3>
    </v-col>
    <v-col :cols="12">
      <h4>Recurso controlado</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.recursoControlado"
      class="pl-4"
      :colSize="field.colSize"
      :key="`recurso-controlado-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h4>Não-monetário</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.naoMonetario"
      class="pl-4"
      :colSize="field.colSize"
      :key="`nao-monetario-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h4>Identificável</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.identificavel"
      class="pl-4"
      :colSize="field.colSize"
      :key="`identificavel-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h4>Sem substância física</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.semSubstancia"
      class="pl-4"
      :colSize="field.colSize"
      :key="`sem-substancia-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h3>IAS38 - CAPEX</h3>
    </v-col>
    <v-col :cols="12">
      <h4>Existência de mercado</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.existenciaMercado"
      class="pl-4"
      :colSize="field.colSize"
      :key="`existencia-mercado-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h4>Intenção de concluir</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.intencaoConcluir"
      class="pl-4"
      :colSize="field.colSize"
      :key="`intencao-concluir-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h4>Mensuração confiável</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.mensuracaoConfiavel"
      class="pl-4"
      :colSize="field.colSize"
      :key="`mensuracao-confiavel-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
    <v-col :cols="12">
      <h3>Responsáveis</h3>
    </v-col>
    <Field
      v-for="(field, index) of fields.responsaveis"
      class="pl-4"
      :colSize="field.colSize"
      :key="`responsaveis-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
  </v-row>
</template>

<script>
import { isEqual } from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    editable: Boolean
  },
  components: {
    Field: () => import("@/components/input-v.vue")
  },
  data() {
    const fieldErrors = new Set();
    return {
      fieldErrors,
      formData: {},
      opts: {
        beneficiosEconomicosFuturo: [
          {
            value: "Geração de Receita"
          },
          {
            value: "Redução de Custo"
          }
        ],
        buOpts: [],
        destinosUso: [
          {
            value: "Interno"
          },
          {
            value: "Externo"
          }
        ],
        emails: [],
        empresasBeneficiadas: [
          {
            value: "FIBX - Banco"
          },
          {
            value: "FIXP - Corretora"
          },
          {
            value: "NFSG - Seguradora"
          }
        ],
        formasSeparacao: [
          {
            value: "Alugar"
          },
          {
            value: "Vender"
          },
          {
            value: "Transferir"
          },
          {
            value: "Trocar"
          },
          {
            value: "Licenciar"
          }
        ],
        impactos: [
          {
            value: "Adição de Feature"
          },
          {
            value: "Automatização de Processos"
          },
          {
            value: "Evolução Incremental"
          },
          {
            value: "Plataforma Nova"
          },
          {
            value: "Produto Novo"
          }
        ],
        impactosFinanceiros: [
          {
            value: "Custo — Evitação (Cost Aoidance)"
          },
          {
            value: "Custo — Redução (Saving)"
          },
          {
            value: "Receita Incremental"
          },
          {
            value: "Receita Nova"
          }
        ],
        iniciativasArtefatosTecnologicos: [
          {
            value: "Automatização de Processos"
          },
          {
            value: "Evolução em Plataformas Internas"
          },
          {
            value: "Plataforma Nova"
          }
        ],
        participantes: [
          {
            value: "People"
          },
          {
            value: "No People"
          },
          {
            value: "People & No People"
          }
        ],
        substanciasFisicas: [
          {
            value: "Não possui"
          },
          {
            value: "Possui"
          }
        ],
        switchOpts: [
          {
            id: false,
            value: "Não"
          },
          {
            id: true,
            value: "Sim"
          }
        ],
        trackings: [
          {
            value: "P&L"
          },
          {
            value: "POWER BI"
          },
          {
            value: "Relatório Gerencial"
          },
          {
            value: "Outros"
          }
        ]
      },
      validationMode: 'save'
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    fields() {
      const form = {
        businessPlan: [
          {
            key: "nome",
            name: "Nome da iniciativa",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            colSize: 12 - (!this.isCreate ? 3 : 0) - (this.formData.status_workflow_label ? 3 : 0),
            editable: this.editable
          },
          {
            key: "status_label",
            name: "Status do Business Plan",
            type: this.$fieldTypes.TEXT,
            hideInForm: this.isCreate,
            editable: false,
            colSize: 3
          },
          {
            key: "status_workflow_label",
            name: "Status do Workflow",
            type: this.$fieldTypes.TEXT,
            hideInForm: !this.formData.status_workflow_label,
            editable: false,
            colSize: 3
          },
          {
            key: "data_inicio",
            name: "Data de início",
            type: this.$fieldTypes.DATE,
            hideInForm: this.isCreate,
            editable: false,
            colSize: 6
          },
          {
            key: "data_fim",
            name: "Data de término",
            type: this.$fieldTypes.DATE,
            hideInForm: this.isCreate,
            editable: false,
            colSize: 6
          },
          {
            key: "business_unit_ids",
            name: "BUs relacionadas a iniciativa",
            type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
            rel: { to: "buOpts", key: "id", name: "label" },
            // rules: [{ rule: "required" }],
            colSize: 12,
            editable: this.editable
          }
        ],
        existenciaMercado: [
          {
            key: "ativo_produto_servico_semelhante",
            name:
              "Há produtos ou serviços semelhantes no mercado ao Ativo resultante do escopo da Iniciativa?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            editable: this.editable,
            defaultValue: false
          },
          {
            key: "ativo_produto_servico_semelhante_desc",
            name:
              "Se houver, cite os principais produtos e/ou serviços semelhantes/concorrentes no mercado (ex: corretoras, instituições financeiras)",
            type: this.$fieldTypes.TEXTAREA,
            editable: this.editable
          },
          {
            key: "mercado_comercializacao",
            name: "Há mercado para comercialização do produto ou serviço?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            editable: this.editable,
            defaultValue: false
          },
          {
            key: "potenciais_consumidores",
            name:
              "Se houver, cite os potenciais usuários do Ativo (ex: assessores, clientes, etc.)",
            type: this.$fieldTypes.TEXTAREA,
            editable: this.editable
          }
        ],
        identificavel: [
          {
            key: "ativo_forma_separacao",
            name:
              "Em caso da empresa optar por separar o ativo da sua estrutura, o ativo resultante do projeto poderá ser identificável da seguinte forma:",
            type: this.$fieldTypes.SELECT,
            rel: { to: "formasSeparacao", key: "value", name: "value" },
            colSize: 12,
            editable: this.editable
          }
        ],
        iniciativa: [
          {
            key: "iniciativa_escopo",
            name:
              "ESCOPO - Detalhar e descrever o que será desenvolvido dentro do escopo da iniciativa:",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6,
            editable: this.editable
          },
          {
            key: "iniciativa_escopo_solucao",
            name:
              "ESCOPO - Detalhar e descrever qual o principal problema ou dificuldade operacional em que a iniciativa tenta solucionar:",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6,
            editable: this.editable
          },
          {
            key: "iniciativa_inovacoes",
            name:
              "INOVAÇÃO - Detalhar e descrever como é hoje o processo ou serviço e como ficará depois do desenvolvimento:",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6,
            editable: this.editable
          },
          {
            key: "iniciativa_riscos_barreiras",
            name:
              "Quais as barreiras e riscos atreladas a execução da iniciativa?",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6,
            editable: this.editable
          },
          {
            key: "iniciativa_relacoes_tecnologia",
            name: "Relação de tecnologias e o seu emprego na iniciativa",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6,
            editable: this.editable
          },
          {
            key: "iniciativa_artefato_tecnologico",
            name: "Artefato tecnológico resultante da iniciativa:",
            type: this.$fieldTypes.SELECT,
            rel: {
              to: "iniciativasArtefatosTecnologicos",
              key: "value",
              name: "value"
            },
            colSize: 6,
            editable: this.editable
          }
        ],
        intencaoConcluir: [
          {
            key: "engenharia_valor",
            name: "A iniciativa passa por Engenharia de Valor?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            editable: this.editable,
            defaultValue: false
          },
          {
            key: "relacao_id_epicos_portifolios",
            name: "Relação ID de Épico de Portfólios ou Épicos",
            type: this.$fieldTypes.TEXTAREA,
            editable: this.editable
          }
        ],
        mensuracaoConfiavel: [
          {
            key: "participantes_registro_horas",
            name:
              "Hoje, TODA a equipe executora (citada na aba Participantes) realiza o registro de horas no VSTS em nível de PBI e/ou task?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 12,
            editable: this.editable,
            defaultValue: false
          }
        ],
        naoMonetario: [
          {
            key: "ativo_moeda_caixa",
            name:
              "O ativo que é representado por unidades de moeda mantidas em caixa?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            editable: this.editable,
            defaultValue: false
          },
          {
            key: "ativo_destinado_uso",
            name: "Utilização do Ativo será destinado para uso",
            type: this.$fieldTypes.SELECT,
            rel: { to: "destinosUso", key: "value", name: "value" },
            editable: this.editable
          }
        ],
        recursoControlado: [
          {
            key: "ativo_controle",
            name:
              "O ativo gerado pelo projeto, sendo materiais, dispositivos, produtos, processos, sistemas ou serviços novos ou substancialmente aprimorados, serão de controle da empresa?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 12,
            editable: this.editable,
            defaultValue: false
          }
        ],
        responsaveis: [
          {
            key: "lider_tecnologia",
            name: "Líder de Tecnologia da iniciativa",
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: "emails", key: "id", name: "label" },
            colSize: 12,
            editable: this.editable
          },
          {
            key: "lider_produto",
            name: "Líder de Produtos da iniciativa",
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: "emails", key: "id", name: "label" },
            colSize: 12,
            editable: this.editable
          },
          {
            key: "lider_negocio",
            name: "Líder de Negócios da iniciativa",
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: "emails", key: "id", name: "label" },
            colSize: 12,
            editable: this.editable
          }
        ],
        semSubstancia: [
          {
            key: "ativo_substancia_fisica",
            name: "Quanto aos elementos físicos, o ativo gerado pelo projeto",
            type: this.$fieldTypes.SELECT,
            rel: { to: "substanciasFisicas", key: "value", name: "value" },
            colSize: 12,
            editable: this.editable
          }
        ]
      };

      Object.values(form).forEach((fields) => {
        fields.forEach((field) => {
          if (field.type === this.$fieldTypes.SWITCH) {
            return;
          }

          const rules = Array.isArray(field.rules) ? [...field.rules] : [];
          const condition = () => this.validationMode === 'approve';
          condition.bind(this);
          rules.push({ rule: 'requiredIf', params: { condition } });
          field.rules = Array.from(rules);
        });
      });

      return form;
    },
    isCreate() {
      return this.$route.meta.mode === "create";
    },
    resource() {
      return this.apiResource("/v1/captacao/tipoRubricas");
    }
  },
  created() {
    this.setInternalData(this.data);
    this.getEmails();
    this.getBUs();
  },
  methods: {
    async getEmails() {
      try {
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/responsaveis/selecao`
        );
        const response = await get();

        if (Array.isArray(response)) {
          this.opts.emails = response.map(responsavel => ({
            ...responsavel,
            label: `${responsavel.nome} (${responsavel.email})`
          }));
          return;
        }

        this.opts.emails = [];
      } catch (error) {
        this.notify(error);
        this.opts.emails = [];
      }
    },
    async getBUs() {
      try {
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/business-units/selecao`
        );
        const response = await get();

        if (Array.isArray(response)) {
          this.opts.buOpts = response.map(bu => {
            const { divisao, nome } = bu;
            return { ...bu, label: `${nome} (${divisao})` };
          });
          return;
        }

        this.opts.buOpts = [];
      } catch (error) {
        this.notify(error);
        this.opts.buOpts = [];
      }
    },
    onError(key, invalid) {
      if (invalid) {
        this.fieldErrors.add(key);
      } else {
        this.fieldErrors.delete(key);
      }

      this.$emit('error', this.fieldErrors.size)
    },
    setInternalData(value) {
      if (!value) {
        return;
      }

      this.formData = { ...value };
    },
    /** @method validate
     * @param {'approve' | 'save'} mode
     * @returns {boolean}
     */
    validate(mode = 'save') {
      this.validationMode = mode;
      const fields = Object.values(this.fields).reduce((acc, fields) => [...acc, ...fields], []);
      const invalidFields = fields.filter(({ hideInForm, valid }) => !hideInForm && !!valid && !valid());
      return invalidFields.length === 0;
    },
  },
  watch: {
    data: {
      deep: true,
      handler(value) {
        if (!isEqual(this.formData, value)) {
          this.setInternalData(value);
        }
      }
    },
    formData: {
      deep: true,
      handler(value) {
        this.$emit("update:data", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h3,
h4 {
  font: 600 0.875rem Poppins;
  margin-left: 0.75rem;
  padding-bottom: 0;
}

h3 {
  font-size: 1.25rem;
}

.textarea-field::v-deep {
  form.v-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .v-label-input + div {
    margin-top: auto;
  }
}

.switch-field::v-deep form {
  flex-direction: row;

  div:not(.v-label-input) {
    flex-grow: 0;
    margin-left: 0.125rem;
  }
}
</style>
