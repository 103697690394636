<script>
import { groupBy } from 'lodash'

export default {
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    groups() {
      const comments = this.comments.map(({ modificado_em, ...rest }) => ({ ...rest, modificado_em: this.$options.filters.toDateTime(modificado_em) }));
      return Object.entries(groupBy(comments, 'nome_fluxo'));
    }
  }
}
</script>

<template>
  <div class="comments-list comments-list-container">
    <div v-for="[groupName, groupComments] of groups" :key="groupName" class="comment-group">
      <div class="group-name">{{ groupName }}</div>
        <div class="comment-item" v-for="comment of groupComments" :key="comment.id">
          <div class="comment">{{ comment.comentario }}</div>
          <div class="comment-responsible">
            <span>por <span class="comment-responsible-name">{{ comment.responsavel }}</span></span>
            <span>as <span class="comment-responsible-date">{{ comment.modificado_em }}</span></span>
          </div>
        </div>
    </div>
  </div>
  
</template>

<style lang="scss" scoped>
.comments-list {
  &.comments-list-container {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding-bottom: 1rem;
  }

  .comment-group {
    background-color: var(--v-table-header-base);
    border-radius: 0.25rem;
    padding: .5rem;

    .comment-item {
      padding: 1rem .5rem;
    }

    .comment-item + .comment-item {
      border-top: solid thin white;
    }

    .comment {
      font-size: 1rem;
      text-align: justify;
    }

    .comment-responsible {
      display: flex;
      flex-direction: column;
      font-size: .85rem;
      font-weight: 300;
      margin-top: 0.25rem;
      text-align: end;

      .comment-responsible-name {
        font-weight: bold;
      }

      .comment-responsible-date {
        font-weight: 400;
      }
    }
  }

  .comment-group + .comment-group {
    margin-top: 1rem;
  }

  .group-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: .5rem;
  }
}
</style>
