<script>
import FormModal from "@/components/form-modal.vue";
import Modal from "@/components/g-modal.vue";
import Table from "@/components/table-v.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    FormModal,
    Modal,
    Table
  },
  props: {
    editable: Boolean,
    participants: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allParticipants: [],
      allocateModal: {
        opened: false,
        value: {}
      },
      selection: [],
      tieModal: {
        opened: false,
        value: {}
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      const buttons = [
        {
          text: "Atualizar",
          icon: "mdi-refresh",
          action: () => {
            this.doReload();
          }
        },
        {
          text: "Vincular participantes",
          icon: "mdi-link-variant-plus",
          hide: !this.editable,
          action: () => {
            this.tieModal.value = {};
            this.tieModal.opened = true;
          }
        },
        {
          text: "Definir percentual",
          icon: "mdi-chart-box-plus-outline",
          hide: !this.participants.length || !this.editable,
          action: () => {
            const participants = this.selection.map(({ id }) => id);
            this.allocateModal.value = { participants };
            this.allocateModal.opened = true;
          }
        }
      ];

      return buttons.filter(({ hide }) => !hide);
    },
    businessPlanId() {
      return this.$route.params.id;
    },
    cols() {
      return [
        {
          key: "matricula",
          name: "Matrícula",
          type: this.$fieldTypes.TEXT
        },
        {
          key: "nome",
          name: "Nome participante",
          type: this.$fieldTypes.TEXT
        },
        {
          key: "especialidade",
          name: "Especialidade",
          type: this.$fieldTypes.TEXT
        },
        {
          key: "formacao",
          name: "Formação técnica",
          type: this.$fieldTypes.TEXT
        }
      ];
    },
    nonTiedParticipants() {
      const tiedIds = this.participants.map(({ id }) => id);
      return this.allParticipants.filter(({ id }) => !tiedIds.includes(id));
    },
    resource() {
      return this.apiResource(
        `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/participantes`
      );
    },
    allocateFields() {
      return [
        {
          key: "participants",
          name: "Participantes",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: this.participants, key: "id", name: "nome" },
          rules: [{ rule: "required" }]
        },
        {
          key: "competenciaIni",
          name: "Competência inicial",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 6
        },
        {
          key: "competenciaFim",
          name: "Competência final",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 6
        },
        {
          key: "percentual",
          name: "Percentual",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "required" }],
          colSize: 6
        }
      ];
    },
    tieFields() {
      return [
        {
          key: "participants",
          name: "Participantes",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: this.nonTiedParticipants, key: "id", name: "label" },
          rules: [{ rule: "required" }]
        }
      ];
    }
  },
  created() {
    this.getAllParticipants();
  },
  methods: {
    contextmenu(row, event, _, col) {
      if (!this.editable) {
        return;
      }

      // 100ms para garantir que qualquer fn chamada por contextmenu tenha efeito antes de renderizar o menu de contexto.
      setTimeout(() => {
        const menu = [
          {
            name: "Desvincular",
            cb: async row => {
              if (!this.selection.includes(row)) {
                this.selection.push(row);
              }

              const response = await this.$refs.confirmUntie.asyncOpen();

              if (!response) {
                return;
              }

              const ids = this.selection.map(({ id }) => id);
              this.doUntieParticipants(ids);
            }
          }
        ];

        this.showContext({
          left: event.clientX,
          top: event.clientY,
          val: row,
          col,
          menu
        });
      }, 100);
    },
    doReload() {
      this.$emit("reload");
    },
    async doAllocateParticipants(
      { participants, competenciaIni, competenciaFim, ...rest },
      closeFn
    ) {
      try {
        if (!participants.length) {
          this.notify("Nenhum participante selecionado.");
          return;
        }

        const { save } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/projetado`
        );
        const start = moment(competenciaIni, "YYYY-MM");
        const end = moment(competenciaFim, "YYYY-MM");
        const monthslength = Math.abs(end.diff(start, "months")) + 1;

        if (!monthslength) {
          this.notify("Intervalo de competências inválido.");
          return;
        }

        const competencias = Array.from({ length: monthslength }, (_, i) =>
          moment(start)
            .add(i, "months")
            .format("YYYY-MM")
        );
        const projetados = participants.reduce((acc, participante_id) => {
          const items = competencias.map(competencia => ({
            ...rest,
            competencia,
            participante_id
          }));
          return [...acc, ...items];
        }, []);

        await save({ projetados });

        this.$notify({
          group: "geral",
          duration: 5000,
          type: "sucess",
          title: "Alocação atualizada",
          text: `Veja as alterações na próxima aba de Alocação.`
        });

        this.selection = [];

        // Avisando o componente pai para recarregar a listagem da aba Alocação
        this.$emit("reloadAllocated");

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async doTieParticipants(values, closeFn) {
      try {
        if (
          !Array.isArray(values.participants) ||
          !values.participants.length
        ) {
          return;
        }

        await this.resource.save({ participantes: values.participants });
        const { length } = values.participants;
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "sucess",
          title:
            length > 1 ? "Participante vinculado" : "Participantes vinculados",
          text: `${length} participante${
            length > 1 ? "s foram" : " foi"
          } adicionado${length > 1 ? "s" : ""} ao BP com sucesso.`
        });
        this.selection = [];
        this.doReload();
        this.validate();

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async doUntieParticipants(ids) {
      try {
        if (!Array.isArray(ids) || !ids.length) {
          return;
        }

        const promises = ids.map(id => this.resource.delete(id));
        await Promise.all(promises);
        this.selection = [];
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "sucess",
          title:
            ids.length > 1
              ? "Participante desvinculado"
              : "Participantes desvinculados",
          text: `${ids.length} participante${
            ids.length > 1 ? "s foram" : " foi"
          } retirado${ids.length > 1 ? "s" : ""} do BP com sucesso.`
        });
        this.doReload();
        this.validate();
      } catch (error) {
        this.notify(error);
      }
    },
    async getAllParticipants() {
      try {
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/participantes/selecao`
        );
        const response = await get();
        this.allParticipants = response.data.map(({ id, matricula, nome }) => ({
          id,
          label: `${matricula || "Sem matricula"} — ${nome}`
        }));
      } catch (error) {
        this.notify(error);
        this.allParticipants = [];
      }
    },
    /** @method validate
     * @param {'approve' | 'save'} mode
     * @returns {boolean}
     */
    validate(mode = 'save') {
      if (mode === 'approve' && !this.participants.length) {
        
        this.$emit('error', 1);
        return false;
      }

      this.$emit('error', 0);
      return true;
    }
  }
};
</script>

<template>
  <Table
    ref="table"
    :actionBarButtons="actionBarButtons"
    :cols="cols"
    class="participants-table"
    :hasExportCSV="false"
    height="unset"
    :rows="participants"
    :selection.sync="selection"
    :selectionCol="editable"
    @contextmenu="contextmenu"
  >
    <FormModal
      :cols="tieFields"
      :opened.sync="tieModal.opened"
      title="Vincular Participantes"
      :value.sync="tieModal.value"
      @save="doTieParticipants"
    />

    <FormModal
      :cols="allocateFields"
      :opened.sync="allocateModal.opened"
      title="Definir percentual"
      :value.sync="allocateModal.value"
      @save="doAllocateParticipants"
    />

    <Modal ref="confirmUntie" title="Desvincular participantes" width="400px">
      <p>
        Tem certeza que deseja desvincular os seguintes participantes deste
        Business Plan?
      </p>

      <ul>
        <li v-for="({ matricula, nome }, key) of selection" :key="key">
          {{ matricula }} — {{ nome }}
        </li>
      </ul>

      <template #buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-5 ml-2"
          color="secondary"
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn class="px-5 ml-2" color="primary" depressed @click="close(true)">
          Desvincular
        </v-btn>
      </template>
    </Modal>
  </Table>
</template>

<style lang="scss" scoped>
.participants-table {
  height: 100%;
  display: flex;
  flex-direction: column;

  &::v-deep {
    .v-card__text {
      flex-shrink: 0;
    }

    .v-data-table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .v-data-table__wrapper {
      flex-grow: 1;
      overflow: auto;
    }

    .v-data-footer {
      flex-shrink: 0;
    }
  }
}
</style>
