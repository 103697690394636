<template>
  <v-row class="pr-4">
    <Field
      v-for="(field, index) of fields"
      class="pl-4"
      :colSize="field.colSize"
      :key="`beneficio-economico-futuro-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
      @error="onError(field.key, $event)"
    ></Field>
  </v-row>
</template>

<script>
import { cloneDeep, isEqual } from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    editable: Boolean
  },
  components: {
    Field: () => import("@/components/input-v.vue")
  },
  data() {
    const fieldErrors = new Set();
    return {
      fieldErrors,
      formData: {},
      opts: {
        empresasBeneficiadas: [
          {
            value: "FIBX - Banco"
          },
          {
            value: "FIXP - Corretora"
          },
          {
            value: "NFSG - Seguradora"
          }
        ],
        impactosFinanceiros: [
          {
            value: "Custo — Evitação (Cost Aoidance)"
          },
          {
            value: "Custo — Redução (Saving)"
          },
          {
            value: "Receita Incremental"
          },
          {
            value: "Receita Nova"
          }
        ],
        trackings: [
          {
            value: "P&L"
          },
          {
            value: "POWER BI"
          },
          {
            value: "Relatório Gerencial"
          },
          {
            value: "Outros"
          }
        ]
      },
      validationMode: 'save'
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    fields() {
      const fields = [
        {
          key: "kpi",
          name: "KPI",
          type: this.$fieldTypes.TEXTAREA,
          editable: this.editable
        },
        {
          key: "impacto_financeiro",
          name: "Impacto Financeiro na Companhia",
          type: this.$fieldTypes.SELECT,
          rel: { to: "impactosFinanceiros", key: "value", name: "value" },
          colSize: 6,
          editable: this.editable
        },
        {
          key: "empresa_beneficiada",
          name: "Empresa beneficiada pelo retorno financeiro",
          type: this.$fieldTypes.SELECT,
          rel: { to: "empresasBeneficiadas", key: "value", name: "value" },
          colSize: 6,
          editable: this.editable
        },
        {
          key: "retorno_financeiro",
          name:
            "Quais os retornos financeiros serão gerados em favor da empresa com o ativo resultante da iniciativa?",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          editable: this.editable
        },
        {
          key: "tracking",
          name: "Forma de Acompanhamento do Retorno Financeiro (TRACKING)",
          type: this.$fieldTypes.SELECT,
          rel: { to: "trackings", key: "value", name: "value" },
          colSize: 6,
          editable: this.editable
        },
        {
          key: "tracking_detalhamento",
          name:
            "Detalhamento de como será evidenciado o Retorno Financeiro (TRACKING)",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          editable: this.editable
        },
        {
          key: "vida_util",
          name: "Vida útil do projeto",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "between", params: { min: 2, max: 10 } }],
          colSize: 6,
          editable: this.editable
        },
        {
          key: "vida_util_justificativa",
          name:
            "Justificativa da expectativa de retorno financeiro assinalada (VIDA ÚTIL/PERÍODO DE AMORTIZAÇÃO):",
          type: this.$fieldTypes.TEXTAREA,
          editable: this.editable
        }
      ];

      fields.forEach((field) => {
        if (field.type === this.$fieldTypes.SWITCH) {
          return;
        }

        const rules = Array.isArray(field.rules) ? [...field.rules] : [];
        const condition = () => this.validationMode === 'approve';
        condition.bind(this);
        rules.push({ rule: 'requiredIf', params: { condition } });
        field.rules = Array.from(rules);
      });

      return fields;
    }
  },
  created() {
    this.setInternalData(this.data);
  },
  methods: {
    onError(key, invalid) {
      if (invalid) {
        this.fieldErrors.add(key);
      } else {
        this.fieldErrors.delete(key);
      }

      this.$emit('error', this.fieldErrors.size)
    },
    setInternalData(value) {
      if (!value) {
        return;
      }

      this.formData = cloneDeep(value);
    },
    /** @method validate
     * @param {'approve' | 'save'} mode
     * @returns {boolean}
     */
    validate(mode = 'save') {
      this.validationMode = mode;
      const invalidFields = this.fields.filter(({ hideInForm, valid }) => !hideInForm && !!valid && !valid());
      return invalidFields.length === 0;
    },
  },
  watch: {
    data: {
      deep: true,
      handler(value) {
        if (!isEqual(this.formData, value)) {
          this.setInternalData(value);
        }
      }
    },
    formData: {
      deep: true,
      handler(value) {
        this.$emit("update:data", value);
      }
    }
  }
};
</script>
