<script>
import Modal from "@/components/g-modal.vue";
import CommentsList from "../../workflow/components/comments-list.vue";

export default {
  components: {
    CommentsList,
    Modal
  },
  props: {
    current: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      commentsModal: {
        opened: false
      },
    }
  },
  computed: {
    approvers() {
      const revisores = this.current?.workflow?.revisores?.map(({ nome }) => nome) ?? [];

      if (revisores.length > 1) {
        const lastOne = revisores.pop();
        return revisores.join(', ') + ` e ${lastOne}`;
      }

      if (revisores.length) {
        const [one] = revisores;
        return one;
      }

      return '';
    },
    comments() {
      const comentarios = this.workflows?.reduce((acc, { workflow: { nome_fluxo, ordem }, revisores }) => {
        const comments = revisores?.reduce((accComments, { comentario, id, criado_em, modificado_em, responsavel: { email, nome } }) => {
          if (!comentario) {
            return accComments;
          }

          return [
            ...accComments,
            {
              comentario,
              criado_em,
              id,
              modificado_em,
              nome_fluxo,
              ordem,
              responsavel: nome ? `${nome}${email ? ` (${email})` : ''}` : email
            }
          ];
        }, []) ?? [];

        return [...acc, ...comments];
      }, []) ?? [];

      comentarios.sort(({ modificado_em: modificadoA, ordem: ordemA }, { modificado_em: modificadoB, ordem: ordemB }) => ordemB - ordemA + modificadoB.localeCompare(modificadoA));

      return comentarios;
    },
    workflowName() {
      return this.current?.workflow?.nome_fluxo ?? '';
    },
    workflowNumber() {
      return this.current?.workflow?.ordem ?? null;
    },
    workflows() {
      return this.current?.workflows ?? [];
    }
  }
}
</script>

<template>
  <div class="workflow-display">
    <div v-if="workflowNumber" class="workflow-display-item workflow-number">{{ workflowNumber }}</div>
    <div class="workflow-display-item workflow-name">{{ workflowName }}</div>
    <div class="workflow-display-item workflow-approvers">{{ approvers }}</div>

    <v-badge v-if="comments.length" class="workflow-display-item" :color="tableColor" :content="comments.length" offset-y="16" offset-x="24" :value="comments.length">
      <v-btn :color="tableColor" text @click="commentsModal.opened = true">
        Ver comentários
      </v-btn>
    </v-badge>

    <Modal v-model="commentsModal.opened" hide-buttons title="Comentários por etapa" width="600px">
      <CommentsList :comments="comments" />
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
  .workflow-display {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 2.25rem;

    .workflow-display-item + .workflow-display-item {
      margin-left: 0.5rem;
    }

    .workflow-number {
      background-color: #FFC440;
      border-radius: 50%;
      color: #FFF;
      font-size: 1.15rem;
      height: 2.25rem;
      line-height: 2;
      text-align: center;
      width: 2.25rem;
    }

    .workflow-name {
      font-weight: bold;
    }

    .workflow-approvers {
      font-weight: 300;
    }
  }
</style>
