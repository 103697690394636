<script>
import Modal from "@/components/g-modal.vue";
import Table from "@/components/table-v.vue";
import { isEqual } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: { Modal, Table },
  props: {
    editable: Boolean,
    value: {
      type: Array,
      required: true
    }
  },
  model: {
    event: "change",
    prop: "value"
  },
  data() {
    const rows = Array.isArray(this.value) ? this.value : [];
    return {
      deleteModal: {
        opened: false,
        data: {},
        title: ""
      },
      renameModal: {
        opened: false,
        data: {}
      },
      rows,
      selection: []
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    acceptFileTypes() {
      return [
        ".bmp",
        ".csv",
        ".doc",
        ".docx",
        ".gif",
        ".jpeg",
        ".jpg",
        ".pdf",
        ".png",
        ".pps",
        ".ppsx",
        ".ppt",
        ".pptx",
        ".psd",
        ".raw",
        ".svg",
        ".tiff",
        ".txt",
        ".webp",
        ".xls",
        ".xlsx",
        ".xml",
        ".zip"
      ].join(",");
    },
    actionBarButtons() {
      const buttons = [
        {
          text: "Atualizar",
          icon: "mdi-refresh",
          action: () => {
            this.doLoad();
          }
        }
      ];

      if (this.editable) {
        buttons.push({
          text: "Anexar",
          icon: "mdi-file-upload",
          action: () => {
            this.$refs.inputFile?.click();
          }
        });
      }

      return buttons;
    },
    businessPlanId() {
      return this.$route.params.id;
    },
    cols() {
      return [
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          width: "80%"
        },
        {
          key: "modificado_em",
          name: "Modificado em",
          type: this.$fieldTypes.DATETIME,
          width: "10%"
        },
        {
          key: "url",
          name: "",
          type: this.$fieldTypes.DOWNLOAD,
          width: "5%"
        }
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/anexos`;
    }
  },
  methods: {
    contextmenu(row, event, _, col) {
      if (!this.editable) {
        return;
      }

      // 100ms para garantir que qualquer fn chamada por contextmenu tenha efeito antes de renderizar o menu de contexto.
      setTimeout(() => {
        const menu = [
          {
            name: "Renomear",
            icon: "mdi-trash",
            cb: row => {
              this.renameModal.data = { ...row };
              this.renameModal.opened = true;
            }
          },
          {
            name:
              this.selection.length > 1 ? "Excluir selecionados" : "Excluir",
            icon: "mdi-trash",
            cb: row => {
              if (!this.selection.includes(row)) {
                this.selection.push(row);
              }

              this.deleteModal.data = { selection: this.selection };
              this.deleteModal.title = `Excluir anexo${
                this.selection.length > 1 ? "s selecionados" : ""
              }`;
              this.deleteModal.opened = true;
            }
          }
        ];

        this.showContext({
          left: event.clientX,
          top: event.clientY,
          val: row,
          col,
          menu
        });
      }, 100);
    },
    async doDelete(closeFn) {
      try {
        const api = this.api();
        const ids = this.selection.map(({ id }) => id);

        if (!ids.length) {
          return;
        }

        if (ids.length > 1) {
          await api.delete(this.resourceUrl, { anexos: ids });
        } else {
          const [id] = ids;
          await api.delete(`${this.resourceUrl}/${id}`);
        }

        this.doLoad();

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        this.notify(error);
      }
    },
    async doLoad() {
      const { get } = this.apiResource(this.resourceUrl);
      const response = await get();
      this.rows = response.data;
      this.selection = [];
    },
    async doRename(closeFn) {
      const { save } = this.apiResource(this.resourceUrl);
      const { id, ...rest } = this.renameModal.data;
      await save({ ...rest }, id);
      this.doLoad();

      if (closeFn) {
        closeFn();
      }
    },
    async onInputChange(event) {
      const files = Array.from(event.target.files);
      const MAX_FILE_SIZE = 10; // 10 MegaBytes
      const attachments = files.reduce((acc, file) => {
        if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
          this.notify({
            error: `O anexo ${file.name} excedeu o limite de ${MAX_FILE_SIZE}MB e não foi anexado.`
          });
          return acc;
        }

        return [...acc, file];
      }, []);

      if (!Array.isArray(attachments) || !attachments.length) {
        return;
      }

      try {
        const api = this.api();
        const formData = new FormData();
        attachments.forEach(file => {
          formData.append("anexos[]", file);
        });
        await api.post(this.resourceUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        this.doLoad();
      } catch (error) {
        this.notify(error);
      }

      event.target.value = null; // Importante para poder voltar a anexar mais arquivos
    }
  },
  watch: {
    value: {
      deep: true,
      handler(next) {
        const rows = Array.isArray(next) ? next : [];

        if (!isEqual(rows, this.rows)) {
          this.rows = rows;
        }
      }
    }
  }
};
</script>

<template>
  <Table
    :actionBarButtons="actionBarButtons"
    class="attachment-table"
    :cols="cols"
    :hasExportCSV="false"
    :rows="rows"
    height="auto"
    :selection.sync="selection"
    :selectionCol="editable"
    @contextmenu="contextmenu"
  >
    <input
      ref="inputFile"
      :accept="acceptFileTypes"
      class="input-file"
      multiple
      type="file"
      @change="onInputChange"
    />

    <!-- Modal de exclusão -->
    <Modal
      :opened.sync="deleteModal.opened"
      :title="deleteModal.title"
      width="400px"
    >
      <p>{{ deleteModal.title }}?</p>
      <ul>
        <li v-for="(sel, key) of deleteModal.data.selection" :key="key">
          {{ sel.nome }}
        </li>
      </ul>

      <template #buttons="{ close }">
        <v-btn
          class="px-5 ml-3"
          color="secondary"
          dark
          depressed
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn depressed color="error" @click="doDelete(close)">
          Excluir
        </v-btn>
      </template>
    </Modal>

    <!-- Modal de edição de nome do arquivo -->
    <Modal
      :opened.sync="renameModal.opened"
      title="Renomear anexo"
      width="400px"
    >
      <v-label>Novo nome</v-label>
      <v-text-field v-model="renameModal.data.nome" dense />

      <template #buttons="{ close }">
        <v-btn
          class="px-5 ml-3"
          color="secondary"
          dark
          depressed
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn depressed color="primary" @click="doRename(close)">
          Renomear
        </v-btn>
      </template>
    </Modal>
  </Table>
</template>

<style lang="scss" scoped>
.input-file {
  display: none;
}

.attachment-table {
  height: 100%;
  display: flex;
  flex-direction: column;

  &::v-deep {
    .v-card__text {
      flex-shrink: 0;
    }

    .v-data-table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .v-data-table__wrapper {
      flex-grow: 1;
      overflow: auto;
    }

    .v-data-footer {
      flex-shrink: 0;
    }
  }
}
</style>
