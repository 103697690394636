import { render, staticRenderFns } from "./businessplan.form.vue?vue&type=template&id=40c5c3a2&scoped=true&"
import script from "./businessplan.form.vue?vue&type=script&lang=js&"
export * from "./businessplan.form.vue?vue&type=script&lang=js&"
import style0 from "./businessplan.form.vue?vue&type=style&index=0&id=40c5c3a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c5c3a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBadge,VBtn,VCard,VItem,VItemGroup,VSpacer,VTextarea,VWindow,VWindowItem})
