<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail
  },
  props: {
    editable: Boolean
  },
  data() {
    return {
      opts: {
        acompanhamentos: []
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    businessPlanId() {
      return this.$route.params.id;
    },
    cols() {
      return [
        {
          key: "nome_gasto",
          name: "Nome do gasto",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "vinculacao",
          name: "Vinculação",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "descricao",
          name: "Descrição",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "acompanhamento",
          name: "Acompanhamento",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "acompanhamentos", key: "id", name: "acompanhamento" },
          colSize: 6
        },
        {
          key: "valor",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 6
        },
        {
          key: "competencia",
          name: "Competência",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.MONTH,
          colSize: 6
        }
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/despesas`;
    }
  },
  created() {
    this.getAcompanhamentos();
  },
  methods: {
    async getAcompanhamentos() {
      try {
        const { get } = this.apiResource(
          `v1/ativacaoped/${this.clientId}/despesas/selecao`
        );
        this.opts.acompanhamentos = [];
        const response = await get();
        this.opts.acompanhamentos = response.sort(
          ({ acompanhamento: a }, { acompanhamento: b }) => a.localeCompare(b)
        );
      } catch (error) {
        this.notify(error);
        this.opts.acompanhamentos = [];
      }
    },
    confirmDeleteMessage({ nome_gasto }) {
      return `Deseja excluir o registro: ${nome_gasto}?`;
    }
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="editable"
    :canEdit="editable"
    class="expenses-table"
    :cols="cols"
    :confirmDeleteMessage="confirmDeleteMessage"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :hasNewButton="editable"
    height="auto"
    :opts="opts"
    :resourceUrl="resourceUrl"
  ></MasterDetail>
</template>

<style lang="scss" scoped>
.expenses-table {
  height: 100%;

  &::v-deep .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text {
      flex-shrink: 0;
    }

    .v-data-table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .v-data-table__wrapper {
      flex-grow: 1;
      overflow: auto;
    }

    .v-data-footer {
      flex-shrink: 0;
    }
  }
}
</style>
