<script>
import Modal from "@/components/g-modal.vue";
import Field from "@/components/input-v.vue";
import Table from "@/components/table-v.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Field,
    Modal,
    Table
  },
  props: {
    editable: Boolean,
    platforms: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      createModalData: {},
      createModalOpened: false,
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      const buttons = [
        {
          text: "Atualizar",
          icon: "mdi-refresh",
          action: () => {
            this.doLoad();
          }
        }
      ];

      if (this.editable) {
        buttons.push({
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.createModalData = {};
            this.createModalOpened = true;
          }
        });
      }

      return buttons;
    },
    cols() {
      return [
        {
          key: "entregavel",
          name: "Entregável",
          hideInform: true,
          type: this.$fieldTypes.TEXT
        },
        {
          key: "nome",
          name: "Nome",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.TEXT,
          colSize: 12
        },
        {
          key: "plataforma_id",
          name: "Plataforma",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "platforms", key: "id", name: "plataforma" },
          colSize: 6
        },
        {
          key: "finalidade",
          name: "Finalidade",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.TEXT,
          colSize: 6
        },
        {
          key: "descricao",
          name: "Descrição",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.TEXTAREA,
          colSize: 12
        },
        {
          key: "data_inicio",
          name: "Data Início",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.DATE,
          colSize: 6
        },
        {
          key: "data_fim",
          name: "Data Fim",
          rules: [{ rule: "required" }],
          type: this.$fieldTypes.DATE,
          colSize: 6
        }
      ];
    },
    fields() {
      return this.cols.filter(({ hideInform }) => !hideInform);
    }
  },
  methods: {
    contextmenu(row, event, _, col) {
      if (!this.editable) {
        return;
      }

      // 100ms para garantir que qualquer fn chamada por contextmenu tenha efeito antes de renderizar o menu de contexto.
      setTimeout(() => {
        const menu = [
          {
            name: "Editar",
            icon:
              "v-icon notranslate mr-3 mdi mdi-square-edit-outline theme--light",
            cb: row => {
              this.createModalData = { ...row };
              this.createModalOpened = true;
            }
          }
        ];

        this.showContext({
          left: event.clientX,
          top: event.clientY,
          val: row,
          col,
          menu
        });
      }, 100);
    },
    doLoad() {
      this.$emit("load");
    },
    doSave(close) {
      const invalidFields = this.fields.filter(
        ({ valid }) => !!valid && !valid()
      );

      if (invalidFields.length) {
        return;
      }

      this.$emit("save", { data: this.createModalData, close });
    }
  }
};
</script>

<template>
  <Table
    :actionBarButtons="actionBarButtons"
    :cols="cols"
    disablePagination
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    height="auto"
    :opts="{ platforms }"
    :rows="rows"
    @contextmenu="contextmenu"
  >
    <Modal :opened.sync="createModalOpened" title="Cadastro de entregáveis">
      <v-container>
        <v-row>
          <Field
            v-for="(field, i) of fields"
            v-model="createModalData[field.key]"
            :block.sync="field.block"
            class="pl-4"
            :colSize="field.colSize"
            :key="i"
            :label="field"
            :opts="{ platforms }"
            :valid.sync="field.valid"
          />
        </v-row>
      </v-container>

      <template #buttons="{ close }">
        <v-spacer />
        <v-btn class="px-5 ml-3" color="secondary" depressed @click="close()">
          Cancelar
        </v-btn>
        <v-btn
          class="px-5 ml-3"
          color="primary"
          dark
          depressed
          @click="doSave(close)"
        >
          Salvar
        </v-btn>
      </template>
    </Modal>
  </Table>
</template>
