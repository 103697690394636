<script>
import Modal from "@/components/g-modal.vue";
import { StatusBusinessPlanEnum, StatusWorkflowEnum } from "@/core/enums/ativacaoped";
import WorkflowDisplay from "./components/workflow-display.vue"
import Alocacao from "./forms/alocacao.vue";
import Evidencias from "./forms/evidencias.vue";
import BeneficioEconomico from "./forms/beneficio-economico.vue";
import BPForm from "./forms/businessplan.vue";
import Discovery from "./forms/discovery.vue";
import OutrasDespesas from "./forms/outras-despesas.vue";
import Participantes from "./forms/participantes.vue";
import Roadmap from "./forms/roadmap.vue";
import VPL from "./forms/vpl.vue";
import { isNil, partition } from "lodash";
import { mapGetters } from "vuex";

const TabEnum = Object.freeze({
  BP: 0,
  BENEFICIO_ECONOMICO: 1,
  DISCOVERY: 2,
  ROADMAP: 3,
  PARTICIPANTES: 4,
  ALOCACAO: 5,
  OUTRAS_DESPESAS: 6,
  VPL: 7,
  MEMORIA_EVIDENCIAS: 8
});

export default {
  components: {
    Alocacao,
    BeneficioEconomico,
    BPForm,
    Discovery,
    Evidencias,
    Modal,
    OutrasDespesas,
    Participantes,
    Roadmap,
    VPL,
    WorkflowDisplay
  },
  data() {
    const self = this;

    return {
      activeItemElement: null,
      commentData: '',
      currentStep: 0,
      discoveryRows: [],
      formData: { anexos: [] },
      opts: {
        statusRubrica: [
          {
            id: 1,
            value: "Ativo"
          },
          {
            id: 0,
            value: "Inativo"
          }
        ]
      },
      platforms: [],
      roadmapRows: [],
      shadowHeader: false,
      shadowFooter: false,
      TabEnum,
      tabs: [
        {
          id: TabEnum.BP,
          label: "Business plan",
          errors: 0,
          disabled: false,
        },
        {
          id: TabEnum.BENEFICIO_ECONOMICO,
          label: "Benefício econômico futuro",
          errors: 0,
          disabled: false,
        },
        {
          id: TabEnum.DISCOVERY,
          label: "Discovery",
          errors: 0,
          get disabled() {
            return !self.businessPlanId;
          },
        },
        {
          id: TabEnum.ROADMAP,
          label: "Roadmap",
          errors: 0,
          get disabled() {
            return !self.businessPlanId || !Array.isArray(self.discoveryRows) || !self.discoveryRows.length;
          },
        },
        {
          id: TabEnum.PARTICIPANTES,
          label: "Participantes",
          errors: 0,
          get disabled() {
            return !self.businessPlanId;
          },
        },
        {
          id: TabEnum.ALOCACAO,
          label: "Alocação",
          errors: 0,
          get disabled() {
            return !self.businessPlanId;
          },
        },
        {
          id: TabEnum.OUTRAS_DESPESAS,
          label: "Outras despesas",
          errors: 0,
          get disabled() {
            return !self.businessPlanId;
          },
        },
        {
          id: TabEnum.VPL,
          label: "VPL",
          errors: 0,
          get disabled() {
            return !self.businessPlanId;
          },
        },
        {
          id: TabEnum.MEMORIA_EVIDENCIAS,
          label: "Memória de cálculo e Evidências",
          errors: 0,
          get disabled() {
            return !self.businessPlanId;
          },
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["clientId", "user"]),
    businessPlanId() {
      return this.$route.params.id || this.formData?.id || null;
    },
    editable() {
      return !this.businessPlanId || (this.isDemandant && (this.formData?.status === StatusBusinessPlanEnum.PREENCHIMENTO || this.formData?.status_workflow === StatusWorkflowEnum.AJUSTE));
    },
    isDemandant() {
      return this.formData?.usuario_id === this.user.id;
    },
    isApprover() {
      const hasPerfil = this.formData.workflow_business_plan?.workflow?.revisores_tipo_id?.some((id) => this.user?.responsavel?.nivel_hierarquico_id === id);
      const wasSelected = this.formData.workflow_business_plan?.workflow?.revisores?.some(({ id }) => this.user?.id === id);
      return !this.isDemandant && (hasPerfil || wasSelected);
    },
    parsedParticipants() {
      if (!Array.isArray(this.formData?.participantes)) {
        return [];
      }

      return this.formData.participantes.map(({ matricula, ...rest }) => ({
        ...rest,
        matricula: matricula ?? "Sem matrícula"
      }));
    },
    resource() {
      return this.apiResource(
        `/v1/ativacaoped/${this.clientId}/business-plans`
      );
    },
    roadmapResource() {
      return this.apiResource(
        `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/roadmap`
      );
    },
    showApproveBtn() {
      return !!this.businessPlanId && this.formData?.status === StatusBusinessPlanEnum.PREENCHIMENTO;
    },
    showSaveBtn() {
      return this.editable && this.currentStep < 2;
    }
  },
  created() {
    if (this.businessPlanId) {
      if (!this.clientId) {
        this.goBack();
        return;
      }

      this.doLoad();
      this.doLoadRoadmap();
      this.getPlatforms();
    }

    if (this.$route.query?.tab === "alocacao") {
      this.currentStep = TabEnum.ALOCACAO;
    }
  },
  mounted() {
    this.updateScrollListener();
  },
  beforeDestroy() {
    if (this.activeItemElement) {
      this.activeItemElement.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    async changeApproval(mode = 'reject') {
      try {
        this.commentData = '';
        const confirm = await this.$refs.commentModal.asyncOpen();

        if (confirm) {
          const status = mode === 'approve' ? StatusWorkflowEnum.APROVADO : mode === 'reject' ? StatusWorkflowEnum.REPROVADO : StatusWorkflowEnum.AJUSTE;
          const resource = this.apiResource(`/v1/ativacaoped/${this.clientId}/workflow-business-plans/${this.formData.workflow_business_plan.workflow_id}/${this.formData.workflow_business_plan.business_plan_workflow_id}/aprovacao-revisor`);
          await resource.save({
            comentario: this.commentData,
            status,
            revisor: this.user.id,
          });
        }
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async doLoad() {
      if (!this.businessPlanId) {
        return;
      }

      try {
        const response = await this.resource.get(this.businessPlanId);
        this.formData = response.data;
        this.formData.status_workflow_label = response.data?.workflow_business_plan?.workflow?.status_label ?? null;
        this.formData.status_workflow = response.data?.workflow_business_plan?.workflow?.status ?? null;
      } catch (error) {
        this.formData = { anexos: [] };
      }
    },
    async doLoadRoadmap() {
      if (!this.businessPlanId) {
        return;
      }

      try {
        const response = await this.roadmapResource.get();
        [this.discoveryRows, this.roadmapRows] = partition(
          response,
          ({ entregavel }) => entregavel === 0
        );
      } catch (error) {
        this.discoveryRows = [];
        this.roadmapRows = [];
      }
    },
    async doSaveRoadmap(tab, { data, close }) {
      if (!this.businessPlanId) {
        return;
      }

      await this.roadmapResource.save(data, data.id);

      const prefix = tab === TabEnum.DISCOVERY ? "primeiro " : "";
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "sucess",
        title: `${
          data.id ? "Edição" : "Inclusão"
        } do ${prefix}entregável concluída`,
        text: `O ${prefix}entregável foi ${
          data.id ? "alterado" : "criado"
        } com sucesso.`
      });

      this.$refs.discovery.validate();

      if (close) {
        close();
      }

      this.doLoadRoadmap();
    },
    async doSave({ skip } = {}) {
      if (!this.validateForms()) {
        return;
      }

      const response = await this.resource.save(this.formData, this.formData.id);

      this.$notify({
        group: "geral",
        duration: 5000,
        type: "sucess",
        title: `${this.businessPlanId ? "Edição" : "Inclusão"} concluída`,
        text: `Business Plan ${
          this.businessPlanId ? "alterado" : "criado"
        } com sucesso.`
      });

      if (skip) {
        return;
      }

      if (this.businessPlanId) {
        this.doLoad();
      } else {
        this.$router.push({
          name: "ativacaoped-projetos-form-edicao",
          params: { id: response.data.id }
        });
      }
    },
    async getPlatforms() {
      try {
        const resource = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/roadmap/plataformas`
        );
        const response = await resource.get();
        this.platforms = response.sort(({ plataforma: a }, { plataforma: b }) =>
          a.localeCompare(b)
        );
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
        this.platforms = [];
      }
    },
    goBack() {
      this.$router.push({
        name: "ativacaoped-projetos"
      });
    },
    goToWorkflow() {
      this.$router.push({
        name: "ativacaoped-workflow-acompanhamento"
      });
    },
    handleScroll() {
      if (!this.activeItemElement) {
        return;
      }

      this.shadowFooter =
        this.activeItemElement.scrollTop +
          this.activeItemElement.clientHeight <=
        this.activeItemElement.scrollHeight - 1;
      this.shadowHeader = this.activeItemElement.scrollTop > 0;
    },
    onError(key, num) {
      const found = this.tabs.find(({ id }) => id === key);

      if (found) {
        found.errors = num;
      }
    },
    reloadAllocatedParticipants() {
      // Se já foi aberto, a aba de Alocação ira recarregar os dados
      this.$refs.alocacao?.doReload();
    },
    required(value) {
      if (isNil(value)) {
        return 'Obrigatório.';
      }

      if (typeof value === "string" && value.length < 3) {
        return 'Mín. 3 caracteres.';
      }

      return true;
    },
    async sendToApproval() {
      try {
        if (!this.validateForms("approve")) {
          this.$refs.approveErrorModal?.open();
          return;
        }

        await this.doSave({ skip: true });
        const resource = this.apiResource(`v1/ativacaoped/${this.clientId}/workflow-business-plans/${this.businessPlanId}`);
        await resource.save({});
  
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "sucess",
          title: "Business Plan enviado para análise",
          text: "Você pode acompanhar o progresso na tela Acompanhamento, dentro do menu Worklow"
        });
        this.goToWorkflow();
      } catch (error) {
        if (!error.isAxiosError) {
          this.notify(error);
        }
      }
    },
    async reloadParticipants() {
      try {
        const { get } = this.apiResource(
          `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/participantes`
        );
        const response = await get();
        this.formData.participantes = response.data;
      } catch (error) {
        this.formData.participantes = [];
      }
    },
    updateScrollListener() {
      this.activeItemElement = this.$el.querySelector(
        ".tab-container + .v-window.v-item-group"
      );

      if (this.activeItemElement) {
        this.activeItemElement.addEventListener("scroll", this.handleScroll);
        setTimeout(this.handleScroll, 100);
      }
    },
    validateForms(mode = 'save') {
      const isAllValid = Object.values(this.$refs).reduce((valid, tab) => {
        if ("validate" in tab) {
          const validTab = tab.validate(mode);
          return valid && validTab;
        }

        return valid;
      }, true);

      return isAllValid;
    }
  },
  watch: {
    clientId(next, old) {
      if (next !== old) {
        this.$router.push({
          name: "ativacaoped-projetos"
        });
      }
    },
    currentStep() {
      setTimeout(this.handleScroll, 100);
    }
  }
};
</script>

<template>
  <v-card class="form-container">
    <v-item-group
      v-model="currentStep"
      class="tab-container"
      :class="{ 'shadow-header': shadowHeader }"
      mandatory
    >
      <v-item
        v-for="{ disabled, errors, id, label } in tabs"
        v-slot="{ active }"
        :key="id"
      >
        <v-btn
          class="tab"
          :class="{ active }"
          dense
          :disabled="disabled"
          text
          tile
          @click="currentStep = id"
        >
          <v-badge :content="errors" color="red" inline :value="errors">
            {{ label }}
          </v-badge>
        </v-btn>
      </v-item>
    </v-item-group>

    <v-window ref="window" v-model="currentStep">
      <v-window-item eager :value="TabEnum.BP">
        <BPForm
          ref="bpform"
          :data.sync="formData"
          :editable="editable"
          @error="onError(TabEnum.BP, $event)"
        />
      </v-window-item>
      <v-window-item eager :value="TabEnum.BENEFICIO_ECONOMICO">
        <BeneficioEconomico
          ref="beneficioEconomico"
          :data.sync="formData"
          :editable="editable"
          @error="onError(TabEnum.BENEFICIO_ECONOMICO, $event)"
        />
      </v-window-item>
      <v-window-item :eager="!!businessPlanId" :value="TabEnum.DISCOVERY">
        <Discovery
          ref="discovery"
          :editable="editable"
          :platforms="platforms"
          :rows="discoveryRows"
          @error="onError(TabEnum.DISCOVERY, $event)"
          @load="doLoadRoadmap"
          @save="doSaveRoadmap(TabEnum.DISCOVERY, $event)"
        />
      </v-window-item>
      <v-window-item :value="TabEnum.ROADMAP">
        <Roadmap
          ref="roadmap"
          :editable="editable"
          :platforms="platforms"
          :rows="roadmapRows"
          @error="onError(TabEnum.ROADMAP, $event)"
          @load="doLoadRoadmap"
          @save="doSaveRoadmap(TabEnum.ROADMAP, $event)"
        />
      </v-window-item>
      <v-window-item :eager="!!businessPlanId" :value="TabEnum.PARTICIPANTES">
        <Participantes
          ref="participantes"
          :editable="editable"
          :participants="parsedParticipants"
          @error="onError(TabEnum.PARTICIPANTES, $event)"
          @reload="reloadParticipants()"
          @reloadAllocated="reloadAllocatedParticipants()"
        />
      </v-window-item>
      <v-window-item :eager="!!businessPlanId" :value="TabEnum.ALOCACAO">
        <Alocacao
          ref="alocacao"
          :editable="editable"
          :participants="parsedParticipants"
          @error="onError(TabEnum.ALOCACAO, $event)"
          @reload="reloadParticipants()"
        />
      </v-window-item>
      <v-window-item :value="TabEnum.OUTRAS_DESPESAS">
        <OutrasDespesas
          ref="outrasDespesas"
          :editable="editable"
          @error="onError(TabEnum.OUTRAS_DESPESAS, $event)"
        />
      </v-window-item>
      <v-window-item :value="TabEnum.VPL">
        <VPL
          ref="vpl"
          :editable="editable"
          :endDate="formData.data_fim"
          :lifeCycle="formData.vida_util"
          :startDate="formData.data_inicio"
          @error="onError(TabEnum.VPL, $event)"
        />
      </v-window-item>
      <v-window-item :value="TabEnum.MEMORIA_EVIDENCIAS">
        <Evidencias
          ref="memoriaEvidencias"
          :editable="editable"
          :value="formData.anexos || []"
          @error="onError(TabEnum.MEMORIA_EVIDENCIAS, $event)"
        />
      </v-window-item>
    </v-window>

    <Modal ref="approveErrorModal" title="Não enviado para aprovação">
      <p>Não foi possivel enviar o Business Plan para aprovação pois um ou mais itens obrigatórios não foi definido.</p>
      <p>Por favor, preencha todas as abas sinalizadas antes de continuar com a aprovação.</p>
    </Modal>

    <Modal ref="commentModal" title="Justificativa">
      <p>Adicione uma justificativa para concluir a ação.</p>
      
      <v-textarea
        outlined
        dense
        :rows="5"
        :rules="[required]"
        :value="commentData"
        hide-details
        placeholder="Escreva um comentáro de forma sucinta."
      />

      <template #buttons="{ close }">
        <v-spacer />
        <v-btn class="px-5 ml-3" color="secondary" depressed @click="close(false)">
          Cancelar
        </v-btn>
        <v-btn
          class="px-5 ml-3"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Salvar
        </v-btn>
      </template>
    </Modal>

    <div class="buttons-container" :class="{ 'shadow-footer': shadowFooter }">
      <WorkflowDisplay v-if="formData.workflow_business_plan" class="ml-5 mr-auto" :current="formData.workflow_business_plan" />

      <template v-if="!businessPlanId || isDemandant">
        <v-btn color="secondary" depressed @click="goBack()">
          Cancelar
        </v-btn>

        <transition name="fade">
          <v-btn v-show="showSaveBtn" color="primary" depressed @click="doSave()">
            Salvar
          </v-btn>
        </transition>

        <v-btn v-show="showApproveBtn" color="primary" depressed @click="sendToApproval()">
          Enviar para aprovação
        </v-btn>
      </template>

      <template v-else-if="businessPlanId && isApprover">
        <v-btn color="secondary" depressed @click="goToWorkflow()">
          Ver todos workflows
        </v-btn>

        <v-btn color="primary" depressed @click="changeApproval('reject')">
          Reprovar workflow
        </v-btn>

        <v-btn color="primary" depressed @click="changeApproval('change')">
          Solicitar alterações ao demandante
        </v-btn>

        <v-btn color="primary" depressed @click="changeApproval('approve')">
          Aprovar workflow
        </v-btn>
      </template>

      <v-btn v-else color="secondary" depressed @click="goBack()">
        Voltar para listagem
      </v-btn>
    </div>
  </v-card>
</template>

<style lang="scss" scoped>
.form-container {
  height: calc(100% - 1rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tab-container + .v-window.v-item-group {
  padding: 1.25rem 0.75rem 0 0.75rem;
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;

  ::v-deep {
    .v-window__container {
      &,
      & .v-window-item {
        height: 100%;
      }
    }
  }
}

.buttons-container {
  background-color: #fff;
  display: flex;
  padding: 1rem 1.25rem 1rem 0;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 1;

  &.shadow-footer {
    box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.1),
      0 -4px 5px 0 rgba(0, 0, 0, 0.07), 0 -1px 10px 0 rgba(0, 0, 0, 0.06);
  }

  .v-btn {
    margin-left: 0.5rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  width: auto;
}

.tab-container {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 0.75rem 0.75rem 0 0.75rem;
  text-align: center;
  transition: box-shadow 0.3s ease;
  z-index: 1;

  &.shadow-header {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1),
      0 4px 5px 0 rgba(0, 0, 0, 0.07), 0 1px 10px 0 rgba(0, 0, 0, 0.06);
  }

  .tab {
    display: flex;
    opacity: 0.8;
    padding: 0 1rem;

    &.active {
      opacity: 1;
      box-shadow: 0 5px 0 var(--v-table-header-base);
    }
  }
}
</style>
