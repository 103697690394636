<script>
import MasterDetail from "@/components/master-detail.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    MasterDetail
  },
  props: {
    editable: Boolean,
    participants: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      competencias: [],
      rows: [],
      selection: []
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      const buttons = [
        {
          text: "Definir percentual",
          icon: "mdi-chart-box-plus-outline",
          hide: !this.participants.length || !this.editable,
          action: () => {
            if (this.$refs.masterDetail) {
              const participantes = this.selection.map(({ id }) => id);
              this.$refs.masterDetail.selectedItem = { participantes };
              this.$refs.masterDetail.saveDialog = true;
            }
          }
        }
      ];

      return buttons.filter(({ hide }) => !hide);
    },
    businessPlanId() {
      return this.$route.params.id;
    },
    cols() {
      const months = this.competencias.map(key => ({
        key,
        name: moment(key, "YYYY-MM").format("MM/YYYY"),
        type: this.$fieldTypes.TEXT,
        hideInform: true
      }));
      return [
        {
          key: "nome",
          name: "Participante",
          type: this.$fieldTypes.TEXT,
          hideInform: true
        },
        ...months,
        {
          key: "participantes",
          name: "Participantes",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: this.participants, key: "id", name: "nome" },
          rules: [{ rule: "required" }],
          hideInTable: true,
          colSize: 12
        },
        {
          key: "competenciaIni",
          name: "Competência inicial",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          hideInTable: true,
          colSize: 6
        },
        {
          key: "competenciaFim",
          name: "Competência final",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          hideInTable: true,
          colSize: 6
        },
        {
          key: "percentual",
          name: "Percentual",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "required" }],
          hideInTable: true,
          colSize: 6
        }
      ];
    },
    contextOptions() {
      if (!this.editable) {
        return [];
      }

      return [
        {
          name: "Definir percentual",
          icon:
            "v-icon notranslate mdi theme--light mdi-chart-box-plus-outline",
          cb: row => {
            if (this.$refs.masterDetail) {
              if (!this.selection.includes(row)) {
                this.selection.push(row);
              }

              const participantes = this.selection.map(({ id }) => id);
              this.$refs.masterDetail.selectedItem = { participantes };
              this.$refs.masterDetail.saveDialog = true;
            }
          }
        }
      ];
    },
    customResource() {
      const resource = this.apiResource(
        `/v1/ativacaoped/${this.clientId}/business-plans/${this.businessPlanId}/projetado`
      );
      const self = this;

      return {
        ...resource,
        async get(...args) {
          try {
            const response = await resource.get(...args);
            self.competencias = [];
            self.rows = response.reduce(
              (
                acc,
                { competencia, participante_id, participante_nome, percentual }
              ) => {
                if (!self.competencias.includes(competencia)) {
                  self.competencias.push(competencia);
                }

                let found = acc.find(r => r.id === participante_id);

                if (!found) {
                  found = {
                    id: participante_id,
                    nome: participante_nome
                  };
                  acc.push(found);
                }

                found[competencia] = percentual ? `${percentual}%` : " -- ";
                return acc;
              },
              []
            );
            self.competencias.sort();
          } catch (error) {
            self.competencias = [];
            self.rows = [];
          }

          return self.rows;
        },
        save(data) {
          const {
            participantes,
            competenciaIni: ini,
            competenciaFim: fim,
            ...rest
          } = data;
          const start = moment(ini, "YYYY-MM");
          const end = moment(fim, "YYYY-MM");
          const length = Math.abs(end.diff(start, "months")) + 1;
          const competencias = Array.from({ length }, (_, i) =>
            moment(start)
              .add(i, "months")
              .format("YYYY-MM")
          );
          const projetados = participantes.reduce((acc, participante_id) => {
            const items = competencias.map(competencia => ({
              ...rest,
              competencia,
              participante_id
            }));
            return [...acc, ...items];
          }, []);
          return resource.save({ projetados });
        }
      };
    }
  },
  methods: {
    /** @method validate
     * @param {'approve' | 'save'} mode
     * @returns {boolean}
     */
    validate(mode = 'save') {
      if (mode === 'approve' && !this.rows.length) {
        
        this.$emit('error', 1);
        return false;
      }

      this.$emit('error', 0);
      return true;
    }
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :actionBarButtons="actionBarButtons"
    :canDelete="false"
    :canEdit="false"
    class="allocation-table"
    :cols="cols"
    :contextOptions="contextOptions"
    :customResource="customResource"
    formTitle="Definir percentual"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :hasNewButton="false"
    height="auto"
    :selection.sync="selection"
    :selectionCol="editable"
  ></MasterDetail>
</template>

<style lang="scss" scoped>
.allocation-table {
  height: 100%;

  &::v-deep .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text {
      flex-shrink: 0;
    }

    .v-data-table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .v-data-table__wrapper {
      flex-grow: 1;
      overflow: auto;
    }

    .v-data-footer {
      flex-shrink: 0;
    }
  }
}
</style>
